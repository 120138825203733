import styled, { css } from 'styled-components';

import { Dropdown } from 'components/Toolkit/Dropdown/Dropdown';
import { InputButton } from 'components/Toolkit/InputButton/InputButton';

import { theme } from 'helpers/Theme';
import type {
  ContainerStyleProps,
  SecondarySelectContainerStyleProps,
} from 'features/location/Location.typed';

const Container = styled.div<ContainerStyleProps>`
  display: flex;
  width: 100%;
  flex-direction: ${({ displayInline }) => (displayInline ? 'column' : 'row')};
`;

const DropdownStyles = css`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const SDropdown = styled(Dropdown)<ContainerStyleProps>`
  width: 100%;
  margin-bottom: ${({ displayInline }) => (displayInline ? '20px' : 0)};
`;

const InputButtonWithSecondarySelect = styled.div<ContainerStyleProps>`
  display: flex;
  flex-direction: ${({ displayInline }) => (displayInline ? 'column' : 'row')};
`;

const SInputButton = styled(InputButton)<ContainerStyleProps>`
  border-top-right-radius: ${({ displayInline }) =>
    displayInline ? theme.borderRadius.default : 0};
  border-bottom-right-radius: ${({ displayInline }) =>
    displayInline ? theme.borderRadius.default : 0};
  margin-bottom: ${({ displayInline }) => (displayInline ? '20px' : 0)};
`;

const SecondarySelectContainer = styled.div<SecondarySelectContainerStyleProps>`
  display: flex;
  min-width: ${({ secondarySelectWidth }) => `${secondarySelectWidth}`};
`;

const Arrow = styled.div`
  position: relative;
  height: 23px;
  box-sizing: border-box;
  width: 21px;
  margin-right: ${({ theme }) => theme.spacing.M16};
`;

const ArrowLeft = styled.div`
  position: absolute;
  top: 0;
  left: 8px;
  bottom: 8px;
  width: 2px;
  background-color: ${({ theme }) => theme.colors.GREY};
  border-radius: 4px 4px 0 0;
`;

const ArrowCurve = styled.div`
  position: absolute;
  bottom: 0;
  left: 8px;
  height: 8px;
  width: 8px;
  border-left: ${({ theme }) => `2px solid ${theme.colors.GREY}`};
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.GREY}`};
  border-radius: 0 0 0 4px;
`;

const ArrowBottom = styled.div`
  position: absolute;
  left: 16px;
  bottom: 0;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.GREY};
  border-radius: 0 4px 4px 0;
  width: 10px;
`;

const ArrowHeadTop = styled.div`
  position: absolute;
  width: 10px;
  left: 16px;
  bottom: 0;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.GREY};
  border-radius: 4px;
  transform: rotateZ(45deg);
  transform-origin: center right;
`;

const ArrowHeadBottom = styled.div`
  position: absolute;
  width: 10px;
  left: 16px;
  bottom: 1px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.GREY};
  border-radius: 4px;
  transform: rotateZ(-45deg);
  transform-origin: center right;
`;

export {
  Container,
  DropdownStyles,
  SDropdown,
  InputButtonWithSecondarySelect,
  SInputButton,
  SecondarySelectContainer,
  Arrow,
  ArrowLeft,
  ArrowCurve,
  ArrowBottom,
  ArrowHeadTop,
  ArrowHeadBottom,
};
