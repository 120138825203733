import { useRef } from 'react';

import { areasRepository } from 'repositories/AreasRepository';
import { ResultError, ResultOk, isOk, ok } from 'domains/Result';
import { separateValues } from 'utils/CombineAndSeparateValues';
import { fireToast } from 'helpers/Toasts';

import { LEVELS } from 'features/location/Location.typed';
import type { CountyData } from 'features/location/Location.typed';
import {
  DEFAULT_PRESELECT,
  GROUP_NAME,
} from 'features/location/Location.constants';
import type { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import { boldMatchingText } from 'helpers/formatting';
import { useLocationContext } from 'features/location/Location.context';
import type { Area } from 'domains/Area';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';

const useLocationShared = () => {
  const {
    selectedCounty,
    setIsLoading,
    setCountyAreas,
    countyAreaSelect,
    levelOneReset,
    levelTwoReset,
    handleClose,
    locationDisplay,
    countyTown,
    countyList,
    isLocationBottomSheetOpen,
    isExpanded,
  } = useLocationContext();

  const countyAreasMapRef = useRef(new Map());

  const mapAreas = (locations: ListItem[], matchingDisplayName?: string) => {
    const mappedAreas = locations.map((location) => {
      const formattedDisplayName =
        matchingDisplayName &&
        location.displayName.includes(matchingDisplayName)
          ? boldMatchingText(location.displayName, matchingDisplayName)
          : undefined;

      return {
        value: location.value,
        displayName: location.displayName,
        group: GROUP_NAME,
        formattedDisplayName,
      };
    });

    return mappedAreas;
  };

  const getCountyAreas = async ({
    value,
    displayName,
  }: CountyData): Promise<ResultOk<Area[]> | ResultError<Error>> => {
    let areas;

    /* Fetch county towns for each area selected by the user once and store 
    result in map. This map is checked before calling the API to
    avoid redundant calls */

    const countyAreasMapRefEntry = countyAreasMapRef.current.get(value);

    if (countyAreasMapRefEntry) {
      areas = ok(countyAreasMapRefEntry);
    } else {
      areas = await areasRepository.getCountyAreas(value, displayName);
    }

    return areas;
  };

  const mapCountyAreas = ({
    countyData,
    areas,
  }: {
    countyData: CountyData;
    areas: Area[];
  }) => {
    const selectedCounty = { ...countyData, group: GROUP_NAME };
    // If a town is currently selected, bold that option if it is available in the area list
    const matchingValue =
      countyTown && locationDisplay?.includes(countyData.displayName);

    const updatedLocationDisplay = matchingValue
      ? locationDisplay
      : countyData.displayName;

    const mappedAreas = matchingValue
      ? mapAreas(areas, locationDisplay)
      : areas;

    const updatedAreas = [
      {
        value: DEFAULT_PRESELECT.value,
        displayName: 'All Counties',
        group: GROUP_NAME,
        displayBackIcon: true,
        formattedDisplayName: '<b>All Counties</b>',
      },
      {
        ...countyData,
        formattedDisplayName: matchingValue
          ? `${countyData.displayName} (All Areas)`
          : `<b>${countyData.displayName} (All Areas)</b>`,
        value: `&&&${countyData.value}&${countyData.displayName}`,
        group: GROUP_NAME,
      },
      ...mappedAreas,
    ];

    const countyTownValue = matchingValue ? countyTown : null;

    return {
      selectedCounty,
      updatedLocationDisplay,
      updatedAreas,
      countyTownValue,
    };
  };

  const handleCountySelect = async (countyData: CountyData) => {
    setIsLoading(true);

    const areas = await getCountyAreas(countyData);

    if (isOk(areas)) {
      countyAreasMapRef.current = new Map(
        countyAreasMapRef.current.set(countyData.value, areas.ok),
      );

      const {
        selectedCounty,
        updatedLocationDisplay,
        updatedAreas,
        countyTownValue,
      } = mapCountyAreas({ countyData, areas: areas.ok });

      setCountyAreas(
        selectedCounty,
        updatedLocationDisplay,
        updatedAreas,
        countyTownValue,
      );
    } else {
      fireToast({
        type: 'ERROR',
        text: 'Oops! Something went wrong, please try again later',
      });
      setIsLoading(false);
    }
  };

  const handleCountyTownSelect = (countyData: CountyData, radius?: string) => {
    const [latitude, longitude, countyTown, county, countyDisplayName] =
      separateValues(countyData.value);
    countyAreaSelect(
      `${countyData.displayName}`,
      {
        latitude,
        longitude,
      },
      {
        value: county,
        displayName: countyDisplayName,
        group: GROUP_NAME,
      },
      countyTown || null,
      radius,
    );
  };

  const handleReset = (level: LEVELS) => {
    switch (level) {
      case LEVELS.LEVEL_ONE:
        levelOneReset();
        return;
      case LEVELS.LEVEL_TWO: {
        const mappedAreas = mapAreas(countyList, selectedCounty?.displayName);
        levelTwoReset(mappedAreas);
        return;
      }
      default:
        return;
    }
  };

  const clearAreaList = () => {
    const mappedAreas = mapAreas(
      countyList,
      selectedCounty?.displayName || DEFAULT_PRESELECT.displayName,
    );
    handleClose(mappedAreas);
  };

  useOnUpdateOnly(() => {
    if (!isExpanded) {
      clearAreaList();
    }
  }, [isExpanded]);

  useOnUpdateOnly(() => {
    if (!isLocationBottomSheetOpen) {
      clearAreaList();
    }
  }, [isLocationBottomSheetOpen]);

  return {
    handleCountySelect,
    handleCountyTownSelect,
    handleReset,
    getCountyAreas,
    mapAreas,
    clearAreaList,
  };
};

export { useLocationShared };
