import styled, { css } from 'styled-components';

import { Select } from 'components/Toolkit/Inputs/Select';

import { ChevronDownOutlineIc } from '@dsch/dd-icons';

const SSelect = styled(Select)`
  width: 100%;
`;

const SecondarySelectStyles = css`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
`;

const DisabledSelect = styled.div<{ displayInline?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ displayInline }) => (displayInline ? '40px' : '48px')};
  padding: 0 ${({ theme }) => theme.spacing.M16};
  background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  border-color: ${({ theme }) => theme.colors.GREY};
  border: 1px solid ${({ theme }) => theme.colors.GREY};
  border-radius: ${({ displayInline, theme }) =>
    displayInline
      ? theme.borderRadius.default
      : `0 ${theme.borderRadius.default} ${theme.borderRadius.default} 0`};
  border-left: ${({ displayInline }) => (displayInline ? 1 : 0)};
  cursor: not-allowed;
`;

const Text = styled.p`
  ${({ theme }) => theme.fontSize.M16}
  color: ${({ theme }) => theme.colors.GREY_LIGHT};
`;

const SIoChevronDownOutline = styled(ChevronDownOutlineIc)`
  color: ${({ theme }) => theme.colors.GREY_LIGHT};
`;

export {
  SSelect,
  SecondarySelectStyles,
  DisabledSelect,
  Text,
  SIoChevronDownOutline,
};
