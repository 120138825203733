import { BottomSheet } from 'components/Toolkit/BottomSheet/BottomSheet';
import { SearchInputAndPrimarySelect } from 'features/location/components/SearchInputAndPrimarySelect/SearchInputAndPrimarySelect';
import { SecondarySelect } from 'features/location/components/SecondarySelect/SecondarySelect';

import * as LocationStyles from 'features/location/Location.styled';

import { LEVELS, LocationProps } from 'features/location/Location.typed';
import {
  BOTTOM_SHEET_HEIGHT,
  DEFAULT_PRESELECT,
  DESKTOP_SKELETON_LIST_LENGTH,
  MOBILE_SKELETON_LIST_LENGTH,
} from 'features/location/Location.constants';
import { useLocationContext } from 'features/location/Location.context';
import { DisplayContainer } from 'components/Support/DisplayContainer/DisplayContainer';
import { useLocationAutocomplete } from 'features/location/hooks/LocationAutocomplete/LocationAutocomplete.hook';

const Location = (props: LocationProps) => {
  const {
    secondarySelectWidth = '100%',
    maxHeight,
    displayInline,
    maxTextContainerWidthMWeb = '100%',
    maxTextContainerWidthWeb = '100%',
    onSelect,
    bottomSheetConfig,
    dropdownConfig,
    heightVariant = 'DEFAULT',
    radiusCallback,
  } = props;

  const {
    isLocationBottomSheetOpen,
    isExpanded,
    setIsLocationBottomSheetOpen,
    setIsExpanded,
    locationDisplay,
    level,
  } = useLocationContext();

  const openBottomSheet = () => setIsLocationBottomSheetOpen(true);
  const closeBottomSheet = () => setIsLocationBottomSheetOpen(false);

  const resetScrollPosition = level === LEVELS.LEVEL_TWO;

  useLocationAutocomplete();

  return (
    <>
      <DisplayContainer config={bottomSheetConfig}>
        <LocationStyles.InputButtonWithSecondarySelect
          displayInline={displayInline}
        >
          <LocationStyles.SInputButton
            isActive={isLocationBottomSheetOpen}
            value={locationDisplay}
            onClick={openBottomSheet}
            placeholder={DEFAULT_PRESELECT.displayName}
            textContainerMaxWidth={maxTextContainerWidthMWeb}
            displayInline={displayInline}
            heightVariant={heightVariant}
          />
          <LocationStyles.SecondarySelectContainer
            secondarySelectWidth={secondarySelectWidth}
          >
            {displayInline && (
              <LocationStyles.Arrow>
                <LocationStyles.ArrowLeft />
                <LocationStyles.ArrowCurve />
                <LocationStyles.ArrowBottom />
                <LocationStyles.ArrowHeadTop />
                <LocationStyles.ArrowHeadBottom />
              </LocationStyles.Arrow>
            )}

            <SecondarySelect
              displayInline={displayInline}
              radiusCallback={radiusCallback}
            />
          </LocationStyles.SecondarySelectContainer>
        </LocationStyles.InputButtonWithSecondarySelect>
        <BottomSheet
          headerText={locationDisplay || DEFAULT_PRESELECT.displayName}
          askToClose={closeBottomSheet}
          isOpen={isLocationBottomSheetOpen}
          optionSelected={Boolean(locationDisplay)}
          resetScrollPosition={resetScrollPosition}
          height={BOTTOM_SHEET_HEIGHT}
          onApply={closeBottomSheet}
        >
          <SearchInputAndPrimarySelect
            skeletonListLength={MOBILE_SKELETON_LIST_LENGTH}
            isLocationBottomSheetOpen={isLocationBottomSheetOpen}
            isExpanded={false}
            onSelect={onSelect}
          />
        </BottomSheet>
      </DisplayContainer>
      <DisplayContainer config={dropdownConfig}>
        <LocationStyles.Container displayInline={displayInline}>
          <LocationStyles.SDropdown
            expanded={isExpanded}
            setExpanded={setIsExpanded}
            placeholder={DEFAULT_PRESELECT.displayName}
            displayedValue={locationDisplay}
            cssStyles={
              !displayInline ? LocationStyles.DropdownStyles : undefined
            }
            maxHeight={maxHeight}
            resetScrollPosition={resetScrollPosition}
            displayInline={displayInline}
            textContainerMaxWidth={maxTextContainerWidthWeb}
            heightVariant={heightVariant}
          >
            <SearchInputAndPrimarySelect
              skeletonListLength={DESKTOP_SKELETON_LIST_LENGTH}
              autoFocus
              isLocationBottomSheetOpen={false}
              isExpanded={isExpanded}
              onSelect={onSelect}
            />
          </LocationStyles.SDropdown>
          <LocationStyles.SecondarySelectContainer
            secondarySelectWidth={secondarySelectWidth}
          >
            {displayInline && (
              <LocationStyles.Arrow>
                <LocationStyles.ArrowLeft />
                <LocationStyles.ArrowCurve />
                <LocationStyles.ArrowBottom />
                <LocationStyles.ArrowHeadTop />
                <LocationStyles.ArrowHeadBottom />
              </LocationStyles.Arrow>
            )}
            <SecondarySelect
              displayInline={displayInline}
              radiusCallback={radiusCallback}
            />
          </LocationStyles.SecondarySelectContainer>
        </LocationStyles.Container>
      </DisplayContainer>
    </>
  );
};

export { Location };
