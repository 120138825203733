import { Tooltip } from 'components/Toolkit/Tooltip/Tooltip';
import { useLocationContext } from 'features/location/Location.context';
import { useRadiusSelect } from 'features/location/hooks/RadiusSelect/RadiusSelect.hook';

import type { SecondarySelectProps } from 'features/location/components/SecondarySelect/SecondarySelect.typed';
import { PAGE } from 'helpers/pages';

import * as Styled from 'features/location/components/SecondarySelect/SecondarySelect.styled';

function SecondarySelect(props: SecondarySelectProps) {
  const { displayInline, radiusCallback } = props;

  const { radius, setRadius, radiusList, selectedCounty, countyTown, page } =
    useLocationContext();

  const [initialSecondarySelectOption] = radiusList;
  const isSecondarySelectDisabled = !countyTown;

  const updateRadius = radiusCallback ?? setRadius;

  const { handleRadiusChange } = useRadiusSelect({
    county: selectedCounty?.displayName,
    countyTown,
    setRadius: updateRadius,
    pushOnChange: page === PAGE.SEARCH,
  });

  return (
    <>
      {isSecondarySelectDisabled && (
        <Tooltip
          subtitle="Please select an area to enable search by distance"
          arrowPosition={['right', 'right', 'right']}
        >
          <Styled.DisabledSelect displayInline={displayInline}>
            <Styled.Text>{initialSecondarySelectOption.label}</Styled.Text>
            <Styled.SIoChevronDownOutline height="1em" width="1em" />
          </Styled.DisabledSelect>
        </Tooltip>
      )}
      {!isSecondarySelectDisabled && (
        <Styled.SSelect
          name="radius"
          onChange={handleRadiusChange}
          disabled={isSecondarySelectDisabled}
          options={radiusList}
          selectStyles={
            !displayInline ? Styled.SecondarySelectStyles : undefined
          }
          heightVariant={displayInline ? 'DEFAULT' : 'LARGE'}
          value={radius}
          willUseSubText={false}
        />
      )}
    </>
  );
}

export { SecondarySelect };
